<template>
  <Intersect
    v-editable="blok"
    v-bind="{ ...(blok.id ? { id: blok.id } : {}) }"
    class="mb-8 size-full"
    :class="[
      ...marginClasses,
      {
        container: blok.in_container,
        '-px-4': !blok.in_container,
      },
    ]"
    :style="{ backgroundColor }"
    :threshold="0.5"
    @enter="intersectPromotionView"
  >
    <div
      class="relative sm:flex"
      :class="{
        'h-[30rem] sm:h-screen': isFullHeight,
        'h-[25rem]': isSmallHeight,
        'h-[30rem]': isMediumHeight,
        'h-[30rem] md:h-[35rem]': isLargeHeight,
        'h-[36rem] md:h-[41rem]': isXtraLargeHeight,
        'sm:flex-col': showButtonBox,
      }"
    >
      <FimPicture
        v-if="image"
        class="z-0"
        :mobile="image.srcMobile"
        :desktop="image.srcDesktop"
        :focus-mobile="image.focusMobile"
        :focus-desktop="image.focusDesktop"
        provider="storyblok"
        layout="fill"
        :preload="preloadMedia"
        :object-position="image.objectPosition"
        :object-fit="image.objectFit || 'cover'"
        :ratio-mobile="image.srcMobile ? undefined : 1 / 1"
        :modifiers="{ smart: true }"
        sizes="(max-width: 1400px) 100vw, 1400px"
        :widths="{
          xs: 500,
          sm: 640,
          md: 960,
          xl: 1280,
          '2xl': 1400,
        }"
      />
      <div
        class="flex size-full px-4 py-6 md:px-6 lg:p-12"
        :class="[...gradientClasses]"
      >
        <div
          class="z-10 flex"
          :class="[
            ...contentWidthClasses,
            {
              'mr-auto': textPosition === 'start',
              'mr-auto sm:mx-auto': textPosition === 'center',
              'mr-auto sm:ml-auto sm:mr-0': textPosition === 'end',
            },
          ]"
        >
          <div
            class="flex w-full flex-col justify-end sm:justify-center"
            :class="{
              [textAlign]: true,
              'text-white': isDark,
              'text-black': isLight,
              'mr-auto': textPosition === 'start',
              'mr-auto sm:mx-auto': textPosition === 'center',
              'mr-auto sm:ml-auto sm:mr-0': textPosition === 'end',
            }"
          >
            <div
              class="flex flex-col gap-3"
              :class="{
                'items-center': textAlign === 'text-center',
                'items-start': textAlign === 'text-left',
                'items-end': textAlign === 'text-right',
              }"
            >
              <p v-if="blok.overline" class="max-w-2xs text-xl font-medium">
                {{ blok.overline }}
              </p>
              <FimPicture
                v-if="logoImageSrc"
                class="cms-picture hidden sm:inline-block"
                height="auto"
                sizes="sm:160"
                provider="storyblok"
                :desktop="logoImageSrc"
                :alt="logoImageAlt"
              />
              <Headline
                v-if="headline"
                v-bind="{ ...headline }"
                class="hyphens-auto break-words"
              >
                {{ headline.text }}
              </Headline>
              <div
                v-if="text && text.body"
                class="with-checkmarks hidden text-xl sm:block"
              >
                <div
                  class="flex flex-col gap-2"
                  :class="{
                    'items-center': textAlign === 'text-center',
                    'items-start': textAlign === 'text-left',
                    'items-end': textAlign === 'text-right',
                  }"
                >
                  <RichTextRenderer :document="text.body" />
                </div>
              </div>
              <CmsButton
                v-if="link"
                :id="'hero-' + blok._uid"
                :blok="link"
                :target="blok.cta_target_blank ? '_blank' : '_self'"
                :type="buttonType"
                :theme="blok.theme"
                data-prefetch
                @mousedown="trackClick"
              >
                {{ link.cta_label }}
              </CmsButton>
            </div>
          </div>
        </div>
      </div>
      <ButtonBox
        v-for="(boxBlok, i) in blok.buttonBox"
        :key="i"
        id-prefix="desktop-"
        class="z-10 mx-auto mb-8 hidden w-[95%] md:flex"
        :blok="boxBlok"
      />
    </div>
    <div
      v-if="text"
      class="with-checkmarks flex flex-col gap-2 px-4 py-2.5 sm:hidden md:p-6"
      :class="{
        'text-white': accentLuminance < 0.5,
        'text-black': accentLuminance >= 0.5,
        [textAlign]: true,
        'items-center': textAlign === 'text-center',
        'items-start': textAlign === 'text-left',
        'items-end': textAlign === 'text-right',
      }"
      :style="{ backgroundColor: accentColor }"
    >
      <RichTextRenderer :document="text.body" />
    </div>
    <ButtonBox
      v-for="(boxBlok, i) in blok.buttonBox"
      :key="i"
      id-prefix="mobile-"
      class="flex w-full md:hidden"
      :blok="boxBlok"
    />
  </Intersect>
</template>

<script setup lang="ts">
import type { SbHero } from '../types/storyblok'
import {
  parseImageRef,
  parseHeadlineRef,
  parseTextRef,
  parseColor,
} from '~/utils/storyblok'

const props = defineProps({
  preloadMedia: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  blok: {
    type: Object as PropType<SbHero>,
    default: () => {},
  },
})
const { marginClasses } = useStoryblokMargins(props.blok)
const { trackButtonClick, trackPromotionView, trackPromotionClick } =
  await useTrackingEvents()

const trackClick = (event: Event) => {
  const element = event?.target as HTMLButtonElement
  trackButtonClick(element)

  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionClick(props.blok)
  }
}

const image = computed(() => parseImageRef(props.blok.image_ref))
const backgroundColor = computed(() =>
  parseColor(image.value?.background_color),
)

const headline = computed(() =>
  parseHeadlineRef(props.blok.headline_ref, {
    tag: HeadlineTag.P,
  }),
)
const link = computed(() => getLinkRef(props.blok.cta_ref))
const text = computed(() => parseTextRef(props.blok.text_ref))

const logoImageSrc = computed(() => props.blok.logo?.filename)
const logoImageAlt = computed(() => props.blok.logo?.alt ?? '')
const showButtonBox = computed(() => Boolean(props.blok.buttonBox?.length))

const textPosition = computed(() => props.blok.text_position || 'start')
const textAlign = computed(() => props.blok.text_align || 'text-left')

const contentWidthClasses = computed(() =>
  ['w-full', 'sm:w-1/2', 'lg:w-1/3'].slice(
    0,
    parseFloat(props?.blok?.max_columns ?? '2'),
  ),
)

const accentColor = computed(() =>
  parseColor(props.blok.accent_color, '#ffffff'),
)
const accentLuminance = computed(() => {
  try {
    const rgb = hex2rgb(accentColor.value)
    return relativeLuminanceW3C(...rgb)
  } catch {
    return 1
  }
})

const gradientClasses = computed(() => {
  if (!props.blok?.show_gradient) {
    return []
  }
  return [
    'gradient',
    isLight.value ? 'gradient-light' : 'gradient-dark',
    {
      start: 'gradient-left',
      center: 'gradient-center',
      end: 'gradient-right',
    }[textPosition.value],
  ]
})

const isDark = computed(() => props.blok.theme === 'dark')
const isLight = computed(() => props.blok.theme === 'light')
const isFullHeight = computed(() => props.blok.height === 'full')
const isSmallHeight = computed(() => props.blok.height === 'sm')
const isMediumHeight = computed(() => props.blok.height === 'md')
const isLargeHeight = computed(() => props.blok.height === 'lg')
const isXtraLargeHeight = computed(() => props.blok.height === 'xl')

const buttonType = computed(() =>
  toButtonType(
    link.value?.cta_type,
    isLight.value ? ButtonType.SECONDARY : ButtonType.PRIMARY,
  ),
)

const intersectPromotionView = (
  _: IntersectionObserverEntry,
  stop: () => void,
) => {
  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionView(props.blok)
  }
  stop()
}
</script>

<style scoped>
.with-checkmarks ul {
  margin-left: 2rem;
}

.gradient::before {
  content: '';
  position: absolute;
  inset: 0;
}

.gradient-light {
  --gradient-color: rgba(255 255 255 / 0.45);
}

.gradient-dark {
  --gradient-color: rgba(0 0 0 / 0.3);
}

.gradient-left::before,
.gradient-center::before,
.gradient-right::before {
  background: linear-gradient(90deg, var(--gradient-color) 0%, transparent 60%);
}

@media (width >= 640px) {
  .gradient-left::before {
    background: linear-gradient(
      90deg,
      var(--gradient-color) 0%,
      transparent 60%
    );
  }

  .gradient-center::before {
    background: radial-gradient(
      circle,
      var(--gradient-color) 20%,
      transparent 100%
    );
  }

  .gradient-right::before {
    background: linear-gradient(
      -90deg,
      var(--gradient-color) 0%,
      transparent 60%
    );
  }
}
</style>
